import React from 'react';
import {
  getDocs,
  collection,
  Firestore,
  query,
  QueryConstraint,
  where,
  limit,
  orderBy,
  doc,
  getDoc,
  updateDoc,
  serverTimestamp,
  startAfter,
  DocumentSnapshot,
} from 'firebase/firestore/lite';
import { useFirestoreClient } from 'src/firebase';
import { getFieldName, MessageRoom } from 'src/models/messageRoom';
import { Collection } from 'src/models/common';
import {
  useLoginAccount,
  LoginAccountState,
} from 'src/globalStates/loginAccount';
import { MessageRoomRepository } from './interface';
import { toMessageRoomFromDoc } from './converter';
import {
  MessageRoomGetItemQuery,
  MessageRoomListQuery,
} from 'src/usecases/messageRoom/reader';
import { validData } from 'src/util/validate';
import { toUpdateFsDoc } from '../common/firestore';
import { message } from 'antd';

export const useMessageRoomRepository = (): MessageRoomRepository => {
  const db = useFirestoreClient();
  const account = useLoginAccount();

  return React.useMemo(
    () => createMessageRoomRepository(db, account),
    [db, account]
  );
};

const createMessageRoomRepository = (
  db: Firestore,
  account: LoginAccountState
) => {
  return {
    async getList() {
      if (account.merchantId === undefined) {
        throw new Error('エラーが発生しました');
      }

      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${
          Collection.MessageRooms
        }`
      );

      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
        orderBy(getFieldName('lastMessageAt'), 'desc'),
      ];

      if (!account.master) {
        queries.push(where(getFieldName('storeId'), 'in', account.storeIds));
      }

      const snapshot = await getDocs(query(collRef, ...queries, limit(300)));

      return snapshot.docs.map((doc) =>
        toMessageRoomFromDoc(doc.data(), doc.id)
      );
    },

    async getDocFromMCusId({ mCustomerId }: MessageRoomListQuery) {
      if (account.merchantId === undefined || mCustomerId === undefined) {
        throw new Error('エラーが発生しました');
      }

      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId.toString()}/${
          Collection.MessageRooms
        }`
      );
      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
        where(getFieldName('mCusId'), '==', mCustomerId),
      ];
      const snapshot = await getDocs(query(collRef, ...queries, limit(300)));

      return snapshot.docs
        .map((doc) => toMessageRoomFromDoc(doc.data(), doc.id))
        .filter((mRoom) => validData([mRoom.storeId], account));
    },

    async getDoc({ mRoomId }: MessageRoomGetItemQuery) {
      if (!!!account.merchantId || !!!mRoomId) {
        throw new Error('エラーが発生しました');
      }

      const docRef = doc(
        db,
        `${Collection.Merchants}/${account.merchantId.toString()}/${
          Collection.MessageRooms
        }/${mRoomId.toString()}`
      );

      const snapshot = await getDoc(docRef);

      if (!snapshot.exists) {
        return null;
      }

      const _mRoom = toMessageRoomFromDoc(snapshot.data()!, snapshot.id);
      if (!account.storeIds?.includes(_mRoom.storeId)) {
        return null;
      }

      if (!validData([_mRoom.storeId], account)) {
        throw new Error('権限がありません');
      }

      return _mRoom;
    },

    async update({ mRoomId }: MessageRoomGetItemQuery) {
      if (!!!account.merchantId || !!!mRoomId) {
        throw new Error('エラーが発生しました');
      }

      const docRef = doc(
        db,
        `${Collection.Merchants}/${account.merchantId.toString()}/${
          Collection.MessageRooms
        }/${mRoomId.toString()}`
      );

      // アップデートデータを前処理
      const docSnap = await getDoc(docRef);
      let updateData:any = toUpdateFsDoc({ unreadMessages: 0,});

      if (docSnap.exists()) {
        const getData = docSnap.data();
        if (getData.hasOwnProperty('isImportant')) {
          await updateDoc(docRef, updateData);
        }
        else {
          await updateDoc(docRef, { isImportant: false, ...updateData });
        }
      }
    },

    // 重要情報を設定するupdate関数
    async updateIsImportant(mRoomId: string , isImportant: boolean): Promise<boolean> {
      
      // オフライン状態を確認
      if (!navigator.onLine) {
        message.error('オフライン状態です。インターネット接続を確認してください。');
        return true;
      }

      if (!!!account.merchantId || !!!mRoomId) {
        throw new Error('エラーが発生しました。');
      }

      const docRef = doc(
        db,
        `${Collection.Merchants}/${account.merchantId!.toString()}/${Collection.MessageRooms
        }/${mRoomId}`
      );

      // 返り値設定
      let ret = true;
      try {
        const dt = serverTimestamp();
        await updateDoc(docRef, { isImportant: isImportant, updatedAt: dt });
        message.success('重要度を反映しました。');
      } catch (err) {
        ret = false;
        throw new Error('エラーが発生しました。');
      }
      finally {
        return ret;
      }
    },

    // 重要設定(true)がされているメッセージルームを取得する
    async getDocIsImportant() {
      // マーチャントIDが取得できない場合
      if (account.merchantId === undefined) {
        throw new Error('エラーが発生しました');
      }

      // メッセージルームのコレクションを取得
      const collRef = collection(
        db,
        `${Collection.Merchants}/${account.merchantId.toString()}/${
          Collection.MessageRooms
        }`
      );

      // クエリの設定
      const queries: Array<QueryConstraint> = [
        where(getFieldName('documentStatus'), '==', 'active'),
        where(getFieldName('isImportant'), '==', true),
        orderBy(getFieldName('lastMessageAt'), 'desc'),
      ];

      const allDocs: MessageRoom[] = [];
      let lastDoc: DocumentSnapshot | null = null;
      let hasMore = true;

      while (hasMore) {
        const pazingQuery = [...queries];

        // startAfter関数で最後のドキュメントを取得
        if (lastDoc) {
          pazingQuery.push(startAfter(lastDoc));
        }

        // DMルームの取得
        const snapshot = await getDocs(query(collRef, ...pazingQuery, limit(300)));
        // ドキュメントの取得
        const docs = snapshot.docs.map((doc) => toMessageRoomFromDoc(doc.data(), doc.id));
        // ドキュメントを追加
        allDocs.push(...docs);
        // 次回取得するかを判定する変数を追加
        lastDoc = snapshot.docs[snapshot.docs.length - 1];
        // ドキュメントがない場合はfalse
        hasMore = snapshot.size === 0 ? false : true;
      }
      return allDocs;
    },
  };
};
