import { message } from 'antd';
import React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Survey, SurveyFormType } from 'src/models/survey';
import { SurveyRepository } from 'src/repositories/survey/interface';
import { useSurveyRepository } from 'src/repositories/survey/repository';
import { v4 as uuidv4 } from 'uuid';
import { useSurveyCacheMutator } from './cache';
import {
  LoginAccountState,
  useLoginAccount,
} from 'src/globalStates/loginAccount';

export const useSurveyForm = () => {
  const repository = useSurveyRepository();
  const navigator = useNavigate();
  const mutator = useSurveyCacheMutator();
  const account = useLoginAccount();

  return React.useMemo(
    () =>
      createPointCardFormConstructor({
        repository,
        navigator,
        mutator,
        account,
      }),
    [repository, navigator, mutator, account]
  );
};

const createPointCardFormConstructor = ({
  repository,
  navigator,
  mutator,
  account,
}: {
  repository: SurveyRepository;
  navigator: NavigateFunction;
  mutator: ReturnType<typeof useSurveyCacheMutator>;
  account: LoginAccountState;
}) => ({
  async submit(value: SurveyFormType) {
    if (!(value.questions.length > 0 && value.questions.length <= 50)) {
      message.error('質問数は1以上50以下で入力してください。');
      return;
    }

    const _questions = value.questions.map((_q) => {
      const _needSelections = _q.type === 'radio' || _q.type === 'checkbox';
      return {
        question: _q.question,
        id: _q.id ?? uuidv4(),
        required: _q.required,
        type: _q.type,
        selections: _needSelections
          ? _q.selectionsForm?.map((_f) => _f.text)
          : null,
      };
    });

    var validate = true;
    for (const _question of _questions) {
      const _duplicate =
        _question.selections?.filter(
          (_name) =>
            (_question.selections?.filter((_n) => _n === _name).length ?? 0) > 1
        ) ?? [];

      if (_duplicate.length > 0) {
        message.error(
          `${_question.question}の${_duplicate[0]}が重複しています。`
        );
        validate = false;
      }
    }
    if (!validate) return;

    try {
      const surveyList = await repository.getList({account});
      if (!!!value.survey) {
        const _survey: Survey = {
          createdAt: new Date(),
          deletedAt: null,
          documentStatus: 'active',
          questions: _questions,
          title: value.title,
          availableStoreIds: value.storeIds,
          updatedAt: new Date(),
          orderIndex:1
        };        
        // 既存のアンケートデータのorderIndexを一括更新
        // 新規追加するアンケートを一番最初に持ってくるので、その次の２番目から設定
        let uniqueNum = 2;
        let orderIdxList: number[] = [];
        for (let idx = 0; idx < surveyList.length; idx++) {
          surveyList[idx].orderIndex = uniqueNum;
          orderIdxList.push(idx);
          uniqueNum++;
        }
        await repository.updateOrderIndexSurveyRepository(surveyList, orderIdxList);
        // アンケートデータ新規追加
        await repository.createDoc(_survey);

        mutator.mutateList({ account });

        message.success('新規作成しました');
      } else {
        const _survey: Survey = {
          ...value.survey,
          questions: _questions,
          title: value.title,
          availableStoreIds: value.storeIds,
          updatedAt: new Date(),
        };
        await repository.updateDoc(_survey);

        mutator.mutateItem({ sId: value.survey.id!, account });

        message.success('保存しました');
      }

      navigator('../');
    } catch (error) {
      message.error('予期せぬエラーが発生しました。再度お試しください。');
    }
    return;
  },
});
