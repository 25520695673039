import useSWR, { mutate } from 'swr';
import { surveyCacheKeyGenerator } from './cache';
import {
  Survey,
  SurveyFormType,
  SurveyId,
  toFormTypeFromSurvey,
} from 'src/models/survey';
import { useSurveyRepository } from 'src/repositories/survey/repository';
import { message } from 'antd';
import {
  LoginAccountState,
  useLoginAccount,
} from 'src/globalStates/loginAccount';

export type SurveyGetListQuery = { account: LoginAccountState };

type SurveyGetListResponse = { surveys: Survey[], account: LoginAccountState, myKey: any};

export const useSurveyList = () => {
  const repository = useSurveyRepository();
  const account = useLoginAccount();

  return useSWR<SurveyGetListResponse>(
    surveyCacheKeyGenerator.generateListKey({
      query: { account },
      ignore: !account.merchantId,
    }),
    async () => {
      // アンケートデータを取得する
      const surveysDataSource = await repository.getList({ account });

      // orderIndexのundifinedが存在する場合に、orderIndexを再設定
      if (surveysDataSource.find(data => data.orderIndex === undefined) !== undefined)
      {
        // 集計したアンケーデータに連番を設定
        let uniqueNum = 1;
        for (let surveysIdx = 0; surveysIdx < surveysDataSource.length; surveysIdx++) {
            surveysDataSource[surveysIdx].orderIndex = uniqueNum;
            uniqueNum++;
        }
      }
      
      return {
        surveys: surveysDataSource,
        account: account,
        myKey: surveyCacheKeyGenerator.generateListKey({
          query: { account },
          ignore: !account.merchantId,
        }),
      };
    },
    {
      onError: (_) => {
        message.error('エラーが発生しました。更新して再度お試しください。');
      },
    }
  );
};

export type SurveyGetItemQuery = { sId: SurveyId; account: LoginAccountState };

export type SurveyGetItemResponse = { survey: SurveyFormType };

export const useSurveyFormItem = ({ sId }: { sId: SurveyId }) => {
  const repository = useSurveyRepository();
  const account = useLoginAccount();

  return useSWR<SurveyGetItemResponse>(
    surveyCacheKeyGenerator.generateItemKey({
      query: { sId, account },
      ignore: !account.merchantId,
    }),
    async () => {
      return {
        survey: toFormTypeFromSurvey(
          await repository.getDoc({ sId: sId, account })
        ),
      };
    },
    {
      onError: (_) => {
        message.error('エラーが発生しました。更新して再度お試しください。');
      },
    }
  );
};
