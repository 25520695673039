import React, { useEffect, useRef, useState } from 'react';
import { Typography } from 'antd';
import { ProColumns, DragSortTable } from '@ant-design/pro-components';
import { Survey, getFieldName } from 'src/models/survey';
import { useNavigate } from 'react-router-dom';
import { useSurveyList } from 'src/usecases/survey/reader';
import { DownloadButton } from 'src/components/ui';
import { useSurveyUsecase } from 'src/usecases/survey/usecase';
import { useSurveyRepository } from 'src/repositories/survey/repository';

export const SurveyTable: React.FC = () => {
  const navigate = useNavigate();

  const onClickEdit = React.useCallback(
    (v: Survey) => {
      return {
        onClick: () => {
          if (!v?.id) return;
          navigate(v.id!);
          return;
        },
      };
    },
    [navigate]
  );

  const values = useSurveyList();

  const _state = useSurveyUsecase();

  const repository = useSurveyRepository();

  const sortDataIdxList = useRef<number[]>([]);
  const [surveysDataSource, setDataSouece] = useState<Survey[]>([]);
  const surveysDisplayDataSource = useRef<Survey[]>([]);
  const [sortedFlg, setSortedFlg] = useState(false);

  // 画面閉じた時の実行関数
  const handleBeforeUnload = () => {
    // データ処理
    repository.updateOrderIndexSurveyRepository(surveysDisplayDataSource.current, sortDataIdxList.current);
  }

  // アンケートデータが更新されるたびに実行
  useEffect(() => {
    // データがあり、ソートされていない場合
    if (values && !sortedFlg) {
      if (values.data !== undefined) {
        setDataSouece(values.data?.surveys ?? []);
      }
    }
  }, [values]);

  // クリーンアップ関数でアンマウント時に処理を実行
  useEffect(() => {
    return () => {
    // データ更新
    if (sortDataIdxList.current !== undefined && sortDataIdxList.current.length != 0) {
      repository.updateOrderIndexSurveyRepository(surveysDisplayDataSource.current, sortDataIdxList.current);
      values.mutate(undefined, false);
      setSortedFlg(false);
    }
    };
  }, []);

  // 画面閉じた際の実行
  useEffect (() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeUnload', handleBeforeUnload);
    }
  }, [handleBeforeUnload])

  const columns: ProColumns[] = [
    {
      // 要変更
      title: '順序',
      dataIndex: 'sort',
      key: 'sort',
      width: 50,
      className: 'drag-visible'
    },
    {
      title: 'タイトル',
      dataIndex: getFieldName('title'),
      key: getFieldName('title'),
    },
    {
      title: '質問項目',
      dataIndex: getFieldName('questions'),
      key: getFieldName('questions'),
      ellipsis: {
        showTitle: true,
      },
      render(_: any, record: Survey, __: number) {
        return (
          <Typography.Text style={{ maxLines: 2 }}>
            {record.questions.map((_q) => _q.question).join(', ')}
          </Typography.Text>
        );
      },
    },
    {
      title: 'ダウンロードデータ',
      dataIndex: 'export',
      key: 'export',
      width: 300,
      render(_: any, record: Survey, __: number) {
        return (
          <a onClick={(event) => event.stopPropagation()} href={_}>
            <DownloadButton
              name="アンケートデータ"
              onClick={(f) => _state.downloadData(f, record.id!)}
            />
          </a>
        );
      },
    },
  ];


  // ソート後のイベント
  const handleDragSortEnd = async (
    beforeIndex: number,
    afterIndex: number,
    newDataSource: Survey[],
  ) => {
    // 入替データ更新
    let fromIdx = beforeIndex;
    let toIdx = afterIndex;

    if (fromIdx > toIdx) {
      const tmp = toIdx;
      toIdx = fromIdx;
      fromIdx = tmp;
    }

    // 入替元と入替先データの間に含まれるデータのorderIdxを全て更新する
    for (let idx = fromIdx; idx <= toIdx; idx++) {
      // データを更新
      newDataSource[idx].orderIndex = idx + 1;
      // 編集したインデックスが含まれているか
      if (!sortDataIdxList.current.includes(idx)) {
        sortDataIdxList.current.push(idx);
      }
    }
    
    // データ更新
    setDataSouece(newDataSource);
    setSortedFlg(true);
    // 更新用に配列の内容を写しておく
    surveysDisplayDataSource.current = newDataSource.concat();
  };

  return (
    <DragSortTable
    columns={columns}
    dataSource={surveysDataSource}
    onRow={onClickEdit}
    rowKey="id"
    search={false}
    pagination={false}
    dragSortKey="sort"
    onDragSortEnd={handleDragSortEnd}
    toolBarRender={false}
    />
  );
};