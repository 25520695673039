import { DocumentData } from 'firebase/firestore/lite';
import { MessageRoomFromJson } from 'smartdishpro-scripts/ts_common';
import { MessageRoom, toMessageRoomId } from 'src/models/messageRoom';

export const toMessageRoomFromDoc = (
  data: DocumentData,
  id: string
): MessageRoom => {
  const _room = MessageRoomFromJson(data);
  return {
    ..._room,
    id: toMessageRoomId(id),
    key: id,
    // データがない場合はデフォルト値を設定
    isImportant: data.isImportant ?? false
  };
};
